<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-17 21:33:58 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:06:41
 * @Description: 菜单管理
 * @FilePath: \src\views\Frame\Menu\MenuManage.vue
-->
<template>
  <div class="vue-box">
    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="success"
        icon="el-icon-plus"
        @click="_OpenNewMenuModel()"
        >添加菜单</el-button
      >
      <el-button
        size="small"
        type="warning"
        icon="el-icon-refresh"
        @click="_Load()"
        >重载数据</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      row-key="menu_id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="80px"
      ></el-table-column>

      <!-- 菜单名称 -->
      <el-table-column
        label="菜单名"
        prop="name"
        align="center"
        width="145px"
      ></el-table-column>

      <!-- 图标 -->
      <el-table-column label="图标" align="center" width="60px">
        <template slot-scope="s">
          <e-icon :icon-name="s.row.icon" style="font-size: 1.3em" />
        </template>
      </el-table-column>

      <!-- 显示排序 -->
      <el-table-column label="显示排序" align="center" width="70px">
        <template slot-scope="s">
          <p v-if="s.row.parent_id !== 'topmenu'">S{{ s.row.sort }}</p>
          <p v-else>{{ s.row.sort }}</p>
        </template>
      </el-table-column>

      <!-- 路由地址 -->
      <el-table-column label="路由地址" width="200px">
        <template slot-scope="s"
          ><span>{{ s.row.route || "——————" }}</span></template
        >
      </el-table-column>

      <!-- 组件路径 -->
      <el-table-column label="组件路径">
        <template slot-scope="s"
          ><span>{{ s.row.uri || "——————————" }}</span></template
        >
      </el-table-column>

      <!-- 菜单状态 -->
      <el-table-column label="菜单状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >可用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else
            >不可用</el-tag
          >
        </template>
      </el-table-column>

      <!-- 认证状态 -->
      <el-table-column label="认证状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="warning"
            v-if="s.row.verify == 1"
            >需要</el-tag
          >
          <el-tag size="small" effect="plain" v-else>不需要</el-tag>
        </template>
      </el-table-column>

      <!-- 显示状态 -->
      <el-table-column label="显示状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.show == 1"
            >显示</el-tag
          >
          <el-tag size="small" effect="plain" type="info" v-else>不显示</el-tag>
        </template>
      </el-table-column>

      <!-- 创建日期 -->
      <el-table-column label="创建日期" align="center" width="135px">
        <template slot-scope="s"
          ><span>{{ s.row.add_time }}</span></template
        >
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateMenuModel(s.row.menu_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelMenu(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[15, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-menu ref="nu-menu"></nu-menu>
  </div>
</template>

<script>
import { getMenuTable, delMenu } from "./api"; //页面专有接口
import NuMenu from "./NUMenu";
export default {
  name: "MenuManage", //对应路由地址
  components: {
    NuMenu,
  },
  data() {
    return {
      p: {
        pageNo: 1, //当前页
        pageSize: 15, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
    };
  },
  methods: {
    //载入数据
    _Load() {
      getMenuTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
      })
        .then((res) => {
          this.TableData = res.data.data; // 重写数据
          this.p.dataCount = res.data.dataCount; // 重写数据行数
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 104014) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开菜单创建组件
    _OpenNewMenuModel() {
      this.$refs["nu-menu"].open(0);
    },

    //打开菜单修改组件
    _OpenUpdateMenuModel(id) {
      this.$refs["nu-menu"].open(id);
    },

    /**
     * 删除菜单
     */
    _DelMenu(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除菜单【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delMenu({
            menu_id: data.menu_id,
          })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
  created() {
    this._Load();
  },
};
</script>

<style></style>
